import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h3>Oh no. You just hit a route that doesn&#39;t exist.</h3>
  </Layout>
)

export default NotFoundPage
